import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Paper, Typography, CircularProgress, IconButton } from '@mui/material';
import AWS from 'aws-sdk';
import axios from 'axios';
import { Delete as DeleteIcon } from '@mui/icons-material';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_REGION;
const API_URL = process.env.REACT_APP_API_URL;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: REGION,
});

const s3 = new AWS.S3();

const AttachmentLoanEdit = () => {
  const { attachmentId } = useParams(); // Update to use attachmentId
  const navigate = useNavigate();
  const [attachmentLoan, setAttachmentLoan] = useState(null);
  const [files, setFiles] = useState({});
  const [previews, setPreviews] = useState({});
  const [uploading, setUploading] = useState(false);
  const [fileUrls, setFileUrls] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!attachmentId) {
      setError("ID not found");
      setLoading(false);
      return;
    }

    const fetchAttachmentLoan = async () => {
      try {
        setLoading(true);
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attachmentsloans/${attachmentId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setAttachmentLoan(response.data);

        // Initialize previews with existing URLs
        const { nric, licence, incomeStatement, salarySlip, epfStatement, utilitiesBill, ctosReport } = response.data;
        setPreviews({
          nricFront: [nric.front],
          nricBack: [nric.back],
          licenceFront: [licence.front],
          licenceBack: [licence.back],
          incomeStatement: [incomeStatement.path],
          salarySlip: [salarySlip.path],
          epfStatement: [epfStatement.path],
          utilitiesBill: [utilitiesBill.path],
          ctosReport: [ctosReport.path],
        });
      } catch (err) {
        console.error('Error fetching data:', err.response ? err.response.data : err.message);
        setError('Error fetching data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAttachmentLoan();
  }, [attachmentId]); // Use attachmentId in dependency array

  const handleFileInput = (e) => {
    const { name, files } = e.target;
    const fileList = Array.from(files);

    setFiles((prevState) => ({
      ...prevState,
      [name]: fileList,
    }));

    const previewUrls = fileList.map((file) => URL.createObjectURL(file));
    setPreviews((prevState) => ({
      ...prevState,
      [name]: previewUrls,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAttachmentLoan((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const uploadFiles = async () => {
    setUploading(true);

    const uploadPromises = Object.keys(files).flatMap((key) =>
      files[key].map(async (file) => {
        const params = {
          Bucket: S3_BUCKET,
          Key: `customerLoans/${Date.now()}_${file.name}`,
          Body: file,
          ContentType: file.type,
        };

        try {
          const data = await s3.upload(params).promise();
          return { [key]: data.Location };
        } catch (err) {
          console.error('Error uploading file:', err);
          alert('Error uploading file. Please try again.');
          return null;
        }
      })
    );

    const uploadResults = await Promise.all(uploadPromises);
    const newFileUrls = uploadResults.reduce((acc, result) => {
      if (result) {
        const [key, value] = Object.entries(result)[0];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(value);
      }
      return acc;
    }, {});

    setFileUrls(newFileUrls);
    setUploading(false);

    return newFileUrls;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uploadedFileUrls = await uploadFiles();

    const updatedData = {
      ...attachmentLoan,
      nric: {
        front: uploadedFileUrls.nricFront ? uploadedFileUrls.nricFront[0] : attachmentLoan.nric.front,
        back: uploadedFileUrls.nricBack ? uploadedFileUrls.nricBack[0] : attachmentLoan.nric.back,
      },
      licence: {
        front: uploadedFileUrls.licenceFront ? uploadedFileUrls.licenceFront[0] : attachmentLoan.licence.front,
        back: uploadedFileUrls.licenceBack ? uploadedFileUrls.licenceBack[0] : attachmentLoan.licence.back,
      },
      incomeStatement: {
        path: uploadedFileUrls.incomeStatement ? uploadedFileUrls.incomeStatement[0] : attachmentLoan.incomeStatement.path,
        status: 'uploaded',
      },
      salarySlip: {
        path: uploadedFileUrls.salarySlip ? uploadedFileUrls.salarySlip[0] : attachmentLoan.salarySlip.path,
        status: 'uploaded',
      },
      epfStatement: {
        path: uploadedFileUrls.epfStatement ? uploadedFileUrls.epfStatement[0] : attachmentLoan.epfStatement.path,
        status: 'uploaded',
      },
      utilitiesBill: {
        path: uploadedFileUrls.utilitiesBill ? uploadedFileUrls.utilitiesBill[0] : attachmentLoan.utilitiesBill.path,
        status: 'uploaded',
      },
      ctosReport: {
        path: uploadedFileUrls.ctosReport ? uploadedFileUrls.ctosReport[0] : attachmentLoan.ctosReport.path,
        status: 'uploaded',
      },
    };

    try {
      const storedToken = localStorage.getItem('token');
      await axios.put(`${API_URL}/attachmentsloans/${attachmentId}`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      alert('Attachment loan updated successfully!');
      navigate('/salesloans');
    } catch (error) {
      console.error('Error updating data to API:', error);
      alert('Error updating data. Please try again.');
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Paper style={{ padding: '16px', marginTop: '16px' }}>
      <Typography variant="h6">Edit Attachment Loan</Typography>
      {attachmentLoan && (
        <form onSubmit={handleSubmit}>
          {/* Add other fields as needed */}
          <div>
            <label>NRIC Front:</label>
            <input type="file" name="nricFront" onChange={handleFileInput} />
            {previews.nricFront && (
              <div>
                <img src={previews.nricFront[0]} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, nricFront: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div>
            <label>NRIC Back:</label>
            <input type="file" name="nricBack" onChange={handleFileInput} />
            {previews.nricBack && (
              <div>
                <img src={previews.nricBack[0]} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, nricBack: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div>
            <label>Licence Front:</label>
            <input type="file" name="licenceFront" onChange={handleFileInput} />
            {previews.licenceFront && (
              <div>
                <img src={previews.licenceFront[0]} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, licenceFront: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div>
            <label>Licence Back:</label>
            <input type="file" name="licenceBack" onChange={handleFileInput} />
            {previews.licenceBack && (
              <div>
                <img src={previews.licenceBack[0]} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, licenceBack: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div>
            <label>Income Statement:</label>
            <input type="file" name="incomeStatement" onChange={handleFileInput} multiple />
            {previews.incomeStatement && (
              <div>
                {previews.incomeStatement.map((src, index) => (
                  <img key={index} src={src} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                ))}
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, incomeStatement: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div>
            <label>Salary Slip:</label>
            <input type="file" name="salarySlip" onChange={handleFileInput} multiple />
            {previews.salarySlip && (
              <div>
                {previews.salarySlip.map((src, index) => (
                  <img key={index} src={src} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                ))}
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, salarySlip: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div>
            <label>EPF Statement:</label>
            <input type="file" name="epfStatement" onChange={handleFileInput} multiple />
            {previews.epfStatement && (
              <div>
                {previews.epfStatement.map((src, index) => (
                  <img key={index} src={src} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                ))}
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, epfStatement: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div>
            <label>Utilities Bill:</label>
            <input type="file" name="utilitiesBill" onChange={handleFileInput} multiple />
            {previews.utilitiesBill && (
              <div>
                {previews.utilitiesBill.map((src, index) => (
                  <img key={index} src={src} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                ))}
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, utilitiesBill: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div>
            <label>CTOS Report:</label>
            <input type="file" name="ctosReport" onChange={handleFileInput} multiple />
            {previews.ctosReport && (
              <div>
                {previews.ctosReport.map((src, index) => (
                  <img key={index} src={src} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                ))}
                <IconButton
                  onClick={() => setFiles((prev) => ({ ...prev, ctosReport: [] }))}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <Button type="submit" variant="contained" color="primary">
            {uploading ? 'Uploading...' : 'Save Changes'}
          </Button>
        </form>
      )}
    </Paper>
  );
};

export default AttachmentLoanEdit;
