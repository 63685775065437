import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography, Button, Checkbox, TextField, Box, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import jsPDF from 'jspdf';
import axios from 'axios';

const SettlementDialog = ({ open, unpaidTerms, selectedTerms, onSelectTerm, onConfirmSettlement, onClose }) => {
  const [totalPayment, setTotalPayment] = useState(0);
  const [cashPayment, setCashPayment] = useState(0);
  const [status, setStatus] = useState('pending');
  const [sst, setSST] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  // Extract customer and outlet information dynamically from unpaidTerms
  const customerName = unpaidTerms[0]?.financeLoanId.customerId.name || 'N/A';
  const mobileNumber = unpaidTerms[0]?.financeLoanId.customerId.mobileNumber || 'N/A';

  const outletData = unpaidTerms[0]?.financeLoanId.outletId || {};
  const outletName = outletData.name || 'N/A';
  const outletEmail = outletData.email || 'N/A';
  const outletPhone = outletData.phone || 'N/A';
  const outletLocation = outletData.location || 'N/A';

  useEffect(() => {
    if (selectedTerms.length === 0) {
      // No terms selected, reset all values to 0
      setTotalPayment(0);
      setSST(0);
      setSubtotal(0);
      setAdjustment(0);
      setGrandTotal(0);
    } else {
      // Calculate totals when there are selected terms
      const total = unpaidTerms
        .filter(term => selectedTerms.includes(term._id))
        .reduce((acc, term) => acc + term.monthlyWithoutInterest, 0);
  
      // const sstAmount = total * 0.10;
      const sstAmount = 0;
      const subtotalAmount = total + sstAmount;
  
      // Calculate adjustment to make grandTotal end in .05
      const decimalPart = subtotalAmount % 1;
      let calculatedAdjustment = 0;
  
      if (decimalPart !== 0) {
        const roundingTarget = 0.05;
        const difference = roundingTarget - (decimalPart % roundingTarget);
  
        calculatedAdjustment = difference > 0 && difference < roundingTarget ? difference : 0;
      }
  
      const calculatedGrandTotal = subtotalAmount + calculatedAdjustment;
  
      setTotalPayment(total);
      setSST(sstAmount);
      setSubtotal(subtotalAmount);
      setAdjustment(calculatedAdjustment);
      setGrandTotal(calculatedGrandTotal);
    }
  }, [selectedTerms, unpaidTerms]);
  

  const handleGenerateInvoice = () => {
    const doc = new jsPDF();

    // Header
    doc.setFontSize(16);
    doc.text('Settlement Receipt', 20, 20);

    // Date and Total Payment
    doc.setFontSize(12);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 20, 30);
    doc.text(`Total Payment: RM ${totalPayment.toFixed(2)}`, 20, 40);

    // Outlet Information
    doc.setFontSize(12);
    doc.text('Outlet Information', 20, 50);
    doc.setFontSize(10);
    doc.text(`Name: ${outletName}`, 20, 55);
    doc.text(`Email: ${outletEmail}`, 20, 60);
    doc.text(`Phone: ${outletPhone}`, 20, 65);
    doc.text(`Address: ${outletLocation}`, 20, 70);

    // Customer Information
    doc.setFontSize(12);
    doc.text('Customer Information', 140, 50);
    doc.setFontSize(10);
    doc.text(`Name: ${customerName}`, 140, 55);
    doc.text(`Mobile: ${mobileNumber}`, 140, 60);
    doc.text(`Settlement Date: ${new Date().toLocaleDateString()}`, 140, 65);

    // Table Headers
    const headers = ['ID', 'Due Date', 'Monthly Amount (RM)', 'Settlement Amount (RM)'];
    const tableData = unpaidTerms
      .filter(term => selectedTerms.includes(term._id))
      .map(term => [
        term.installmentNumber,
        new Date(term.dueDate).toLocaleDateString(),
        term.monthlyInstalmentAmount.toFixed(2),
        term.monthlyWithoutInterest.toFixed(2),
      ]);

    // Add table to PDF
    doc.autoTable({
      startY: 80,
      head: [headers],
      body: tableData,
      theme: 'grid',
      headStyles: { fillColor: [41, 128, 185] },
    });

    // Summary Information
    let finalY = doc.lastAutoTable.finalY + 10;
    doc.text(`Total: RM ${totalPayment.toFixed(2)}`, 20, finalY);
    doc.text(`TAX (0%): RM ${sst.toFixed(2)}`, 20, finalY + 5);
    doc.text(`Subtotal: RM ${subtotal.toFixed(2)}`, 20, finalY + 10);
    doc.text(`Adjustment: RM ${adjustment.toFixed(2)}`, 20, finalY + 15);
    doc.setFontSize(12);
    doc.text(`Grand Total: RM ${grandTotal.toFixed(2)}`, 20, finalY + 25);

    // Save the PDF
    doc.save(`settlement_receipt_${new Date().toISOString()}.pdf`);
  };
  const handleConfirmSettlement = async () => {
    // Convert to two decimal places for accurate comparison
    const cashValue = parseFloat(cashPayment).toFixed(2);
    const grandTotalValue = parseFloat(grandTotal).toFixed(2);
  
    if (cashValue !== grandTotalValue) {
      setErrorMessage("Cash payment must match the grand total to proceed with the settlement.");
      return;
    }
  
    const payload = selectedTerms.map(termId => ({
      id: termId,
      fieldsToUpdate: { status: "paid" }
    }));
  
    try {
      const storedToken = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/paymenterms/bulk-update`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        }
      });
      handleGenerateInvoice();
      onConfirmSettlement();
    } catch (error) {
      console.error("Error updating payment terms:", error);
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Settlement Details</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box>
            <Typography variant="h6">Outlet Information</Typography>
            <Typography>Name: {outletName}</Typography>
            <Typography>Email: {outletEmail}</Typography>
            <Typography>Phone: {outletPhone}</Typography>
            <Typography>Address: {outletLocation}</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Customer Information</Typography>
            <Typography>Name: {customerName}</Typography>
            <Typography>Mobile: {mobileNumber}</Typography>
            <Typography>Settlement Date: {new Date().toLocaleDateString()}</Typography>
          </Box>
        </Box>

        {unpaidTerms.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Monthly Amount (RM)</TableCell>
                  <TableCell>Settlement Amount (Interest-Free)</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unpaidTerms.map((term) => (
                  <TableRow key={term._id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedTerms.includes(term._id)}
                        onChange={() => onSelectTerm(term._id)}
                      />
                    </TableCell>
                    <TableCell>{term.installmentNumber}</TableCell>
                    <TableCell>{new Date(term.dueDate).toLocaleDateString()}</TableCell>
                    <TableCell>{term.monthlyInstalmentAmount.toFixed(2)}</TableCell>
                    <TableCell>{term.monthlyWithoutInterest.toFixed(2)}</TableCell>
                    <TableCell>{term.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No unpaid or pending terms available.</Typography>
        )}

        <Box mt={2} display="flex" flexDirection="column">
          <Typography variant="h6">Total Payment: RM {totalPayment.toFixed(2)}</Typography>
          <Typography>TAX (0%): RM {sst.toFixed(2)}</Typography>
          <Typography>Subtotal: RM {subtotal.toFixed(2)}</Typography>
          <Typography>Adjustment: RM {adjustment.toFixed(2)}</Typography>
          <Typography variant="h5">Grand Total: RM {grandTotal.toFixed(2)}</Typography>

          <Box mt={2} display="flex" alignItems="center">
            <FormControl variant="outlined" style={{ width: '200px', marginRight: '20px' }}>
              <InputLabel>Status</InputLabel>
              <Select value={status} onChange={(e) => setStatus(e.target.value)} label="Status">
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Cash"
              type="number"
              value={cashPayment}
              onChange={(e) => setCashPayment(e.target.value)}
              variant="outlined"
              style={{ width: '200px' }}
            />
          </Box>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleGenerateInvoice} color="primary" variant="outlined">Download Invoice</Button>
        <Button onClick={handleConfirmSettlement} color="primary" variant="contained">Confirm Settlement</Button>
        <Button onClick={onClose} color="secondary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettlementDialog;
