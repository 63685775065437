import React, { useState, useEffect } from 'react';
import { Box, Container, Accordion, AccordionSummary, AccordionDetails, TextField, Button, Typography, InputAdornment, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import './new.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import UploadImage from '../../components/UploadImage';
import { MobileNumberMask, PhoneNumberMask, NRICNoMask } from './MobileNumberMask';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
  customButton: {
    backgroundColor: '#037b7d',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2c9294',
    },
  },
});


const NewCustomerLoan = () => {
  const [customerId, setCustomerId] = useState(null);
  const classes = useStyles();
  const apiURL = process.env.REACT_APP_API_URL;
  const [bikeModels, setBikeModels] = useState([]);
  const navigate = useNavigate();

  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    nricNo: '',
    age: 0,
    mobileNumber: '',
    bestTimeToCall: '',
    email: '',
    gender: '',
    maritalStatus: '',
    race: '',
    address: '',
    city: '',
    postcode: '',
    state: '',
  });

  const [employmentDetails, setEmploymentDetails] = useState({
    occupation: '',
    occupationType: '',
    employmentLength: '',
    employmentSegment: '',
    employerName: '',
    employerPhoneNumber: '',
    supervisorName: '',
    supervisorPhoneNumber: '',
    employerAddress: '',
    city: '',
    postcode: '',
    state: '',
    monthlyGrossSalary: '',
    monthlyNetSalary: '',
    salaryCreditingBank: '',
    salaryCreditingAccountNumber: '',
    salaryCreditingDay: '',
    epf: '',
  });

  const [referenceLoan1, setReferenceLoan1] = useState({
    name: '',
    nricNo: '',
    contactNumber: '',
    relationship: '',
  });

  const [referenceLoan2, setReferenceLoan2] = useState({
    name: '',
    nricNo: '',
    contactNumber: '',
    relationship: '',
  });

  const [financeLoan, setFinanceLoan] = useState({
    product: '',
    customerCommitment: '',
    condition: '',
    model: {
      manufacturing: '',
      model: '',
      variants: '',
      color: '',

    },
    salePrice: '',
    deposit: '',
    tenure: '',
    financingAmount: 0,
    monthlyInstalmentAmount: 0,
    totalLoanPayment: 0,
  });

  const [attachmentLoan, setAttachmentLoan] = useState({
    nric: { front: '', back: '' },
    licence: { front: '', back: '' },
    incomeStatement: [],
    salarySlip: [],
    epfStatement: [],
    utilitiesBill: [],
    ctosReport: '',
  });
  const [saveStatus, setSaveStatus] = useState({
    customerDetails: { disabled: false, color: 'primary' },
    employmentDetails: { disabled: false, color: 'primary' },
    referenceLoan1: { disabled: false, color: 'primary' },
    referenceLoan2: { disabled: false, color: 'primary' },
    financeLoan: { disabled: false, color: 'primary' },
    attachmentLoan: { disabled: false, color: 'primary' },
  });

  useEffect(() => {
    const fetchBikeModels = async () => {
      try {
        const response = await axios.get(`${apiURL}/bikemodels`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setBikeModels(response.data);
      } catch (error) {
        console.error('Error fetching bike models:', error);
      }
    };

    fetchBikeModels();

    calculateFinance();
  }, [financeLoan.salePrice, financeLoan.deposit, financeLoan.tenure]);

  const handleInputChange = (section, field, value) => {
    switch (section) {
      case 'customerDetails':
        setCustomerDetails({ ...customerDetails, [field]: value });
        break;
      case 'employmentDetails':
        setEmploymentDetails({ ...employmentDetails, [field]: value });
        break;
      case 'referenceLoan1':
        setReferenceLoan1({ ...referenceLoan1, [field]: value });
        break;
      case 'referenceLoan2':
        setReferenceLoan2({ ...referenceLoan2, [field]: value });
        break;
      case 'financeLoan':
        if (field === 'model') {
          setFinanceLoan({
            ...financeLoan,
            model: { ...financeLoan.model, ...value },
          });
        } else {
          setFinanceLoan({ ...financeLoan, [field]: value });
        }
        break;
      case 'attachmentLoan':
        if (['nric', 'licence'].includes(field)) {
          setAttachmentLoan({
            ...attachmentLoan,
            [field]: { ...attachmentLoan[field], ...value },
          });
        } else {
          setAttachmentLoan({ ...attachmentLoan, [field]: value });
        }
        break;
      default:
        break;
    }
  };

  const handleSave = async (section) => {
    try {
      let data;
      let url;
      switch (section) {
        case 'customerDetails':
          data = customerDetails;
          url = `${apiURL}/customerloans`;
          const customerResponse = await axios.post(url, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          const newCustomer = customerResponse.data;
          setCustomerId(newCustomer._id); // Set the customerId after successful creation
          setSaveStatus({
            ...saveStatus,
            customerDetails: { disabled: true, color: 'warning' }, // Change color to 'warning' (light orange)
          });
          alert('Customer details saved successfully!');
          break;
        case 'employmentDetails':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...employmentDetails, customerId };
          url = `${apiURL}/employmentloans`;
          break;
        case 'referenceLoan1':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...referenceLoan1, customerId };
          url = `${apiURL}/referencesloans`;
          break;
        case 'referenceLoan2':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...referenceLoan2, customerId };
          url = `${apiURL}/referencesloans`;
          break;
        case 'financeLoan':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...financeLoan, customerId };
          url = `${apiURL}/financeloans`;
          break;
        case 'attachmentLoan':
          if (!customerId) {
            alert('Please save customer details first.');
            return;
          }
          data = { ...attachmentLoan, customerId };
          url = `${apiURL}/attachmentsloans`;
          break;
        default:
          return;
      }

      if (section !== 'customerDetails') {
        const response = await axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const newRecord = response.data;
        console.log(`${section} saved successfully:`, newRecord);
        setSaveStatus({
          ...saveStatus,
          [section]: { disabled: true, color: 'warning' }, // Change color to 'warning' (light orange)
        });
        alert(`${section.replace(/([A-Z])/g, ' $1')} saved successfully!`);
      }
    } catch (error) {
      console.error(`Error saving ${section}:`, error);
      alert(`Error saving ${section}: ${error.message}`);
    }
  };

  const calculateFinance = () => {
    const { salePrice, deposit, tenure } = financeLoan;
    const financeAmount = salePrice - deposit;
    const interestRate = tenure === 6 ? 0.05 : tenure === 12 ? 0.10 : tenure === 18 ? 0.15 : tenure === 24 ? 0.20 : tenure === 30 ? 0.25 : tenure === 36 ? 0.30 : tenure === 42 ? 0.35 : tenure === 48 ? 0.40 : tenure === 54 ? 0.45 : 0.50;
    const interestValue = financeAmount * interestRate;
    const totalAmount = financeAmount + interestValue;

    // Calculate monthly installment
    const monthlyInstallmentRaw = totalAmount / tenure;
    const monthlyInstallment = Math.ceil(monthlyInstallmentRaw);

    setFinanceLoan((prevState) => ({
      ...prevState,
      financingAmount: financeAmount,
      totalLoanPayment: totalAmount,
      monthlyInstalmentAmount: monthlyInstallment,
    }));
  };


  const handleUploadComplete = (uploadedFiles) => {
    handleInputChange('attachmentLoan', 'nric', {
      front: uploadedFiles.nricFront,
      back: uploadedFiles.nricBack,
    });
    handleInputChange('attachmentLoan', 'licence', {
      front: uploadedFiles.licenceFront,
      back: uploadedFiles.licenceBack,
    });
  };

  return (
    <div className="new">
      <div className="addtable">
        <Sidebar />
        <div className="newContainer">
          <Navbar />
          <div className="bottom"></div>
          <Container component="main" maxWidth="lg">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ px: 2 }}
            >
              <Box
                width="100%"
                maxWidth="800px"
                p={3}
                boxShadow={3}
                borderRadius={2}
                bgcolor="background.paper"
              >
                <h1>New Customer Loan</h1>
                <div className="formContainer">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color={saveStatus.customerDetails.color} disabled={saveStatus.customerDetails.disabled}>
                        Customer Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Name"
                          value={customerDetails.name}
                          onChange={(e) => handleInputChange('customerDetails', 'name', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="NRIC No"
                          value={customerDetails.nricNo}
                          onChange={(e) => handleInputChange('customerDetails', 'nricNo', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                          InputProps={{
                            inputComponent: NRICNoMask,
                          }}
                        />
                        <TextField
                          label="Age"
                          value={customerDetails.age}
                          onChange={(e) => handleInputChange('customerDetails', 'age', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}

                        />
                        <TextField
                          label="Mobile Number"
                          value={customerDetails.mobileNumber}
                          onChange={(e) => handleInputChange('customerDetails', 'mobileNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                          // InputProps={{
                          //   inputComponent: MobileNumberMask,
                          // }}
                        />
                        <TextField
                          label="Best Time To Call"
                          value={customerDetails.bestTimeToCall}
                          onChange={(e) => handleInputChange('customerDetails', 'bestTimeToCall', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="Email"
                          value={customerDetails.email}
                          onChange={(e) => handleInputChange('customerDetails', 'email', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="Gender"
                          value={customerDetails.gender}
                          onChange={(e) => handleInputChange('customerDetails', 'gender', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                        <TextField
                          label="Marital Status"
                          value={customerDetails.maritalStatus}
                          onChange={(e) => handleInputChange('customerDetails', 'maritalStatus', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="single">Single</MenuItem>
                          <MenuItem value="married">Married</MenuItem>
                          <MenuItem value="divorced">Divorced</MenuItem>
                          <MenuItem value="separated">Separated</MenuItem>
                          <MenuItem value="widowed">Widowed</MenuItem>
                          <MenuItem value="registered partnership">Registered Partnership</MenuItem>
                        </TextField>
                        <TextField
                          label="Race"
                          value={customerDetails.race}
                          onChange={(e) => handleInputChange('customerDetails', 'race', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="Malay">Malay</MenuItem>
                          <MenuItem value="Chinese">Chinese</MenuItem>
                          <MenuItem value="Indian">Indian</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                        <TextField
                          label="Address"
                          value={customerDetails.address}
                          onChange={(e) => handleInputChange('customerDetails', 'address', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="City"
                          value={customerDetails.city}
                          onChange={(e) => handleInputChange('customerDetails', 'city', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />

                        <TextField
                          label="Postcode"
                          value={customerDetails.postcode}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,5}$/.test(value)) { // Only allow up to 5 digits
                              handleInputChange('customerDetails', 'postcode', value);
                            }
                          }}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        />
                        <TextField
                          label="State"
                          value={customerDetails.state}
                          onChange={(e) => handleInputChange('customerDetails', 'state', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.customerDetails.disabled}
                        >
                          <MenuItem value="Johor">Johor</MenuItem>
                          <MenuItem value="Kedah">Kedah</MenuItem>
                          <MenuItem value="Kelantan">Kelantan</MenuItem>
                          <MenuItem value="Malacca">Malacca</MenuItem>
                          <MenuItem value="Negeri Sembilan">Negeri Sembilan</MenuItem>
                          <MenuItem value="Pahang">Pahang</MenuItem>
                          <MenuItem value="Penang">Penang</MenuItem>
                          <MenuItem value="Perlis">Perlis</MenuItem>
                          <MenuItem value="Perak">Perak</MenuItem>
                          <MenuItem value="Sabah">Sabah</MenuItem>
                          <MenuItem value="Sarawak">Sarawak</MenuItem>
                          <MenuItem value="Selangor">Selangor</MenuItem>
                          <MenuItem value="Terengganu">Terengganu</MenuItem>
                        </TextField>
                        <Button
                          variant="contained"
                          className={classes.customButton}
                          color={saveStatus.customerDetails.color}
                          disabled={saveStatus.customerDetails.disabled}
                          onClick={() => handleSave('customerDetails')}
                        >
                          Save Customer Details
                        </Button>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color={saveStatus.employmentDetails.color} disabled={saveStatus.employmentDetails.disabled}>
                        Employment Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Occupation"
                          value={employmentDetails.occupation}
                          onChange={(e) => handleInputChange('employmentDetails', 'occupation', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Occupation Type"
                          value={employmentDetails.occupationType}
                          onChange={(e) => handleInputChange('employmentDetails', 'occupationType', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Contract">Contract</MenuItem>
                          <MenuItem value="Employed">Employed</MenuItem>
                          <MenuItem value="Freelance">Freelance</MenuItem>
                          <MenuItem value="Part-Time">Part-Time</MenuItem>
                          <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                          <MenuItem value="Unemployed">Unemployed</MenuItem>
                          <MenuItem value="Probation">Probation</MenuItem>
                          <MenuItem value="Pensioned">Pensioned</MenuItem>
                        </TextField>
                        <TextField
                          label="Employment Length"
                          value={employmentDetails.employmentLength}
                          onChange={(e) => handleInputChange('employmentDetails', 'employmentLength', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Less than 1 year">Less than 1 year</MenuItem>
                          <MenuItem value="Between 1 and 3 years">Between 1 and 3 years</MenuItem>
                          <MenuItem value="Between 3 and 5 years">Between 3 and 5 years</MenuItem>
                          <MenuItem value="More than 5 years">More than 5 years</MenuItem>
                        </TextField>
                        <TextField
                          label="Employment Segment"
                          value={employmentDetails.employmentSegment}
                          onChange={(e) => handleInputChange('employmentDetails', 'employmentSegment', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="GIG(e.g. Grab / FoodPanda)">GIG(e.g. Grab / FoodPanda)</MenuItem>
                          <MenuItem value="Police / Army / FireFighter">Police / Army / FireFighter</MenuItem>
                          <MenuItem value="Government Staff">Government Staff</MenuItem>
                          <MenuItem value="Driver(Lorry, Van, Citylink)">Driver(Lorry, Van, Citylink)</MenuItem>
                          <MenuItem value="Security Guard">Security Guard</MenuItem>
                          <MenuItem value="Semi-Skilled(Factory, Construction & Technical Worker)">Semi-Skilled(Factory, Construction & Technical Worker)</MenuItem>
                          <MenuItem value="Retail(e.g. Sales, F&B, Mart, Fashion, Hotel, Travel)">Retail(e.g. Sales, F&B, Mart, Fashion, Hotel, Travel)</MenuItem>
                          <MenuItem value="Office Worker / Admin/ Clerk/ HR">Office Worker / Admin/ Clerk/ HR</MenuItem>
                          <MenuItem value="Professional(e.g. Engineer, Banker, Healthcare, IT, Teacher)">Professional(e.g. Engineer, Banker, Healthcare, IT, Teacher)</MenuItem>
                        </TextField>
                        <TextField
                          label="Employer Name"
                          value={employmentDetails.employerName}
                          onChange={(e) => handleInputChange('employmentDetails', 'employerName', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Employer Telephone Number"
                          value={employmentDetails.employerPhoneNumber}
                          onChange={(e) => handleInputChange('employmentDetails', 'employerPhoneNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                          // InputProps={{
                          //   inputComponent: PhoneNumberMask,
                          // }}
                        />
                        <TextField
                          label="Supervisor Name"
                          value={employmentDetails.supervisorName}
                          onChange={(e) => handleInputChange('employmentDetails', 'supervisorName', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Supervisor Telephone Number"
                          value={employmentDetails.supervisorPhoneNumber}
                          onChange={(e) => handleInputChange('employmentDetails', 'supervisorPhoneNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                          // InputProps={{
                          //   inputComponent: PhoneNumberMask,
                          // }}
                        />
                        <TextField
                          label="Employer Address"
                          value={employmentDetails.employerAddress}
                          onChange={(e) => handleInputChange('employmentDetails', 'employerAddress', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="City"
                          value={employmentDetails.city}
                          onChange={(e) => handleInputChange('employmentDetails', 'city', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />

                        <TextField
                          label="Postcode"
                          value={employmentDetails.postcode}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,5}$/.test(value)) { // Only allow up to 5 digits
                              handleInputChange('employmentDetails', 'postcode', value);
                            }
                          }}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />

                        <TextField
                          label="State"
                          value={employmentDetails.state}
                          onChange={(e) => handleInputChange('employmentDetails', 'state', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Johor">Johor</MenuItem>
                          <MenuItem value="Kedah">Kedah</MenuItem>
                          <MenuItem value="Kelantan">Kelantan</MenuItem>
                          <MenuItem value="Malacca">Malacca</MenuItem>
                          <MenuItem value="Negeri Sembilan">Negeri Sembilan</MenuItem>
                          <MenuItem value="Pahang">Pahang</MenuItem>
                          <MenuItem value="Penang">Penang</MenuItem>
                          <MenuItem value="Perlis">Perlis</MenuItem>
                          <MenuItem value="Perak">Perak</MenuItem>
                          <MenuItem value="Sabah">Sabah</MenuItem>
                          <MenuItem value="Sarawak">Sarawak</MenuItem>
                          <MenuItem value="Selangor">Selangor</MenuItem>
                          <MenuItem value="Terengganu">Terengganu</MenuItem>
                        </TextField>
                        <TextField
                          label="Monthly Gross Salary"
                          value={employmentDetails.monthlyGrossSalary}
                          onChange={(e) => handleInputChange('employmentDetails', 'monthlyGrossSalary', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Monthly Net Salary"
                          value={employmentDetails.monthlyNetSalary}
                          onChange={(e) => handleInputChange('employmentDetails', 'monthlyNetSalary', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Salary Crediting Bank"
                          value={employmentDetails.salaryCreditingBank}
                          onChange={(e) => handleInputChange('employmentDetails', 'salaryCreditingBank', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Affin Bank Berhad">Affin Bank Berhad</MenuItem>
                          <MenuItem value="Affin Islamic Bank Berhad">Affin Islamic Bank Berhad</MenuItem>
                          <MenuItem value="Alliance Bank Malaysia Berhad">Alliance Bank Malaysia Berhad</MenuItem>
                          <MenuItem value="Alliance Islamic Bank Malaysia Berhad">Alliance Islamic Bank Malaysia Berhad</MenuItem>
                          <MenuItem value="Al Rajhi Banking & Investment Corporation (Malaysia) Berhad">Al Rajhi Banking & Investment Corporation (Malaysia) Berhad</MenuItem>
                          <MenuItem value="AmBank (M) Berhad">AmBank (M) Berhad</MenuItem>
                          <MenuItem value="Bank Islam Malaysia Berhad">Bank Islam Malaysia Berhad</MenuItem>
                          <MenuItem value="Bank Kerjasama Rakyat Malaysia Berhad">Bank Kerjasama Rakyat Malaysia Berhad</MenuItem>
                          <MenuItem value="Bank Muamalat Malaysia Berhad">Bank Muamalat Malaysia Berhad</MenuItem>
                          <MenuItem value="Bank of China (Malaysia) Berhad">Bank of China (Malaysia) Berhad</MenuItem>
                          <MenuItem value="Bank Pertanian Malaysia Berhad (Agrobank)">Bank Pertanian Malaysia Berhad (Agrobank)</MenuItem>
                          <MenuItem value="Bank Simpanan Nasional">Bank Simpanan Nasional</MenuItem>
                          <MenuItem value="CIMB Bank Berhad">CIMB Bank Berhad</MenuItem>
                          <MenuItem value="CIMB Islamic Bank Berhad">CIMB Islamic Bank Berhad</MenuItem>
                          <MenuItem value="Citibank Berhad">Citibank Berhad</MenuItem>
                          <MenuItem value="Hong Leong Bank Berhad">Hong Leong Bank Berhad</MenuItem>
                          <MenuItem value="Hong Leong Islamic Bank Berhad">Hong Leong Islamic Bank Berhad</MenuItem>
                          <MenuItem value="HSBC Amanah Malaysia Berhad">HSBC Amanah Malaysia Berhad</MenuItem>
                          <MenuItem value="HSBC Bank Malaysia Berhad">HSBC Bank Malaysia Berhad</MenuItem>
                          <MenuItem value="Industrial and Commercial Bank of China (Malaysia) Berhad">Industrial and Commercial Bank of China (Malaysia) Berhad</MenuItem>
                          <MenuItem value="Kuwait Finance House">Kuwait Finance House</MenuItem>
                          <MenuItem value="Malayan Banking Berhad">Malayan Banking Berhad</MenuItem>
                          <MenuItem value="MBSB Bank Berhad">MBSB Bank Berhad</MenuItem>
                          <MenuItem value="OCBC Bank (Malaysia) Berhad">OCBC Bank (Malaysia) Berhad</MenuItem>
                          <MenuItem value="Public Bank Berhad">Public Bank Berhad</MenuItem>
                          <MenuItem value="RHB Bank Berhad">RHB Bank Berhad</MenuItem>
                          <MenuItem value="RHB Islamic Bank Berhad">RHB Islamic Bank Berhad</MenuItem>
                          <MenuItem value="Standard Chartered Bank Malaysia Berhad">Standard Chartered Bank Malaysia Berhad</MenuItem>
                          <MenuItem value="Standard Chartered Saadiq Berhad">Standard Chartered Saadiq Berhad</MenuItem>
                          <MenuItem value="United Overseas Bank (Malaysia) Berhad">United Overseas Bank (Malaysia) Berhad</MenuItem>
                        </TextField>
                        <TextField
                          label="Salary Crediting Account Number"
                          value={employmentDetails.salaryCreditingAccountNumber}
                          onChange={(e) => handleInputChange('employmentDetails', 'salaryCreditingAccountNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        />
                        <TextField
                          label="Salary Crediting Day"
                          type="date"
                          value={employmentDetails.salaryCreditingDay}
                          onChange={(e) => handleInputChange('employmentDetails', 'salaryCreditingDay', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          label="Customer with EPF"
                          value={employmentDetails.epf}
                          onChange={(e) => handleInputChange('employmentDetails', 'epf', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.employmentDetails.disabled}
                        >
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </TextField>
                        <Button
                          variant="contained"
                          className={classes.customButton}
                          color={saveStatus.employmentDetails.color}
                          disabled={saveStatus.employmentDetails.disabled}
                          onClick={() => handleSave('employmentDetails')}
                        >
                          Save Employment Details
                        </Button>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color={saveStatus.referenceLoan1.color} disabled={saveStatus.referenceLoan1.disabled}>
                        Reference 1
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Name"
                          value={referenceLoan1.name}
                          onChange={(e) => handleInputChange('referenceLoan1', 'name', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.referenceLoan1.disabled}
                        />
                        <TextField
                          label="NRIC No"
                          value={referenceLoan1.nricNo}
                          onChange={(e) => handleInputChange('referenceLoan1', 'nricNo', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.referenceLoan1.disabled}
                          InputProps={{
                            inputComponent: NRICNoMask,
                          }}
                        />
                        <TextField
                          label="Mobile Number"
                          value={referenceLoan1.mobileNumber}
                          onChange={(e) => handleInputChange('referenceLoan1', 'contactNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.referenceLoan1.disabled}
                          InputProps={{
                            inputComponent: MobileNumberMask,
                          }}
                        />
                        <TextField
                          label="Relationship"
                          value={referenceLoan1.relationship}
                          onChange={(e) => handleInputChange('referenceLoan1', 'relationship', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.referenceLoan1.disabled}
                        >
                          <MenuItem value="In-laws">In-laws</MenuItem>
                          <MenuItem value="Parents">Parents</MenuItem>
                          <MenuItem value="Siblings">Siblings</MenuItem>
                          <MenuItem value="Spouse">Spouse</MenuItem>
                          <MenuItem value="Child">Child</MenuItem>
                          <MenuItem value="Relative">Relative</MenuItem>
                        </TextField>
                        <Button
                          variant="contained"
                          color={saveStatus.referenceLoan1.color}
                          disabled={saveStatus.referenceLoan1.disabled}
                          className={classes.customButton}
                          onClick={() => handleSave('referenceLoan1')}
                        >
                          Save Reference Loan 1
                        </Button>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color={saveStatus.referenceLoan2.color} disabled={saveStatus.referenceLoan2.disabled}>
                        Reference 2
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Name"
                          value={referenceLoan2.name}
                          onChange={(e) => handleInputChange('referenceLoan2', 'name', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.referenceLoan2.disabled}
                        />
                        <TextField
                          label="NRIC No"
                          value={referenceLoan2.nricNo}
                          onChange={(e) => handleInputChange('referenceLoan2', 'nricNo', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.referenceLoan2.disabled}
                          InputProps={{
                            inputComponent: NRICNoMask,
                          }}
                        />
                        <TextField
                          label="Mobile Number"
                          value={referenceLoan2.mobileNumber}
                          onChange={(e) => handleInputChange('referenceLoan2', 'contactNumber', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.referenceLoan2.disabled}
                          InputProps={{
                            inputComponent: MobileNumberMask,
                          }}
                        />
                        <TextField
                          label="Relationship"
                          value={referenceLoan2.relationship}
                          onChange={(e) => handleInputChange('referenceLoan2', 'relationship', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.referenceLoan2.disabled}
                        >
                          <MenuItem value="In-laws">In-laws</MenuItem>
                          <MenuItem value="Parents">Parents</MenuItem>
                          <MenuItem value="Siblings">Siblings</MenuItem>
                          <MenuItem value="Spouse">Spouse</MenuItem>
                          <MenuItem value="Child">Child</MenuItem>
                          <MenuItem value="Relative">Relative</MenuItem>
                        </TextField>
                        <Button
                          variant="contained"
                          color={saveStatus.referenceLoan2.color}
                          disabled={saveStatus.referenceLoan2.disabled}
                          className={classes.customButton}
                          onClick={() => handleSave('referenceLoan2')}
                        >
                          Save Reference Loan 2
                        </Button>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color={saveStatus.financeLoan.color} disabled={saveStatus.financeLoan.disabled}>
                        Finance Loan
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <TextField
                          label="Product"
                          value={financeLoan.product}
                          onChange={(e) => handleInputChange('financeLoan', 'product', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        >
                          <MenuItem value="SHOP">SHOP</MenuItem>
                          <MenuItem value="AEON">AEON</MenuItem>
                          <MenuItem value="JCL">JCL</MenuItem>
                        </TextField>

                        <TextField
                          label="Customer Commitment"
                          value={financeLoan.customerCommitment}
                          onChange={(e) => handleInputChange('financeLoan', 'customerCommitment', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        />

                        <TextField
                          label="Condition"
                          value={financeLoan.condition}
                          onChange={(e) => handleInputChange('financeLoan', 'condition', e.target.value)}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        >
                          <MenuItem value="New">New</MenuItem>
                          <MenuItem value="Used">Used</MenuItem>
                        </TextField>


                        <TextField
                          label="Manufacturing"
                          value={financeLoan.model.manufacturing}
                          onChange={(e) => handleInputChange('financeLoan', 'model', { manufacturing: e.target.value, model: '', variants: '', color: '' })}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        >
                          {/* Get unique manufacturing names */}
                          {[...new Set(bikeModels.map(model => model.manufacturing))].map((manufacturing) => (
                            <MenuItem key={manufacturing} value={manufacturing}>
                              {manufacturing}
                            </MenuItem>
                          ))}
                        </TextField>

                        {/* <TextField
                          label="Model"
                          value={financeLoan.model.model}
                          onChange={(e) => handleInputChange('financeLoan', 'model', { model: e.target.value, variants: '', color: '' })}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        >
                          
                          {bikeModels
                            .filter((bike) => bike.manufacturing === financeLoan.model.manufacturing)
                            .map((bike) => (
                              <MenuItem key={bike._id} value={bike.model}>
                                {bike.model}
                              </MenuItem>
                            ))}
                        </TextField> */}
                        <TextField
                          label="Model"
                          value={financeLoan.model.model}
                          onChange={(e) => handleInputChange('financeLoan', 'model', { model: e.target.value, variants: '', color: '' })}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        >
                          {Array.from(new Set(bikeModels
                            .filter((bike) => bike.manufacturing === financeLoan.model.manufacturing)
                            .map((bike) => bike.model)
                          )).map((uniqueModel) => (
                            <MenuItem key={uniqueModel} value={uniqueModel}>
                              {uniqueModel}
                            </MenuItem>
                          ))}
                        </TextField>

                        {/* 
                        <TextField
                          label="Variants"
                          value={financeLoan.model.variants}
                          onChange={(e) => handleInputChange('financeLoan', 'model', { variants: e.target.value, color: '' })}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        >
                          
                          {bikeModels
                            .filter((bike) => bike.manufacturing === financeLoan.model.manufacturing && bike.model === financeLoan.model.model)
                            .map((bike) => (
                              <MenuItem key={bike._id} value={bike.variants}>
                                {bike.variants}
                              </MenuItem>
                            ))}
                        </TextField> */}
                        <TextField
                          label="Variants"
                          value={financeLoan.model.variants}
                          onChange={(e) => handleInputChange('financeLoan', 'model', { variants: e.target.value, color: '' })}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        >
                          {Array.from(new Set(bikeModels
                            .filter((bike) => bike.manufacturing === financeLoan.model.manufacturing && bike.model === financeLoan.model.model)
                            .map((bike) => bike.variants)
                          )).map((uniqueVariant) => (
                            <MenuItem key={uniqueVariant} value={uniqueVariant}>
                              {uniqueVariant}
                            </MenuItem>
                          ))}
                        </TextField>


                        <TextField
                          label="Color"
                          value={financeLoan.model.color}
                          onChange={(e) => handleInputChange('financeLoan', 'model', { color: e.target.value })}
                          select
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                        >
                          {/* Filter colors by selected manufacturing, model, and variant */}
                          {bikeModels
                            .filter((bike) => bike.manufacturing === financeLoan.model.manufacturing && bike.model === financeLoan.model.model && bike.variants === financeLoan.model.variants)
                            .map((bike) => (
                              <MenuItem key={bike._id} value={bike.color}>
                                {bike.color}
                              </MenuItem>
                            ))}
                        </TextField>


                        <TextField
                          label="Sale Price"
                          type="number"
                          value={financeLoan.salePrice}
                          onChange={(e) => handleInputChange('financeLoan', 'salePrice', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                          }}
                        />

                        <TextField
                          label="Deposit"
                          type="number"
                          value={financeLoan.deposit}
                          onChange={(e) => handleInputChange('financeLoan', 'deposit', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                          }}
                        />

                        <TextField
                          label="Tenure"
                          type="number"
                          value={financeLoan.tenure}
                          onChange={(e) => handleInputChange('financeLoan', 'tenure', e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                          select
                        >
                          {[6, 12, 18, 24, 30, 36, 42, 48, 54, 60].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option} months
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          label="Financing Amount"
                          value={financeLoan.financingAmount}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                            readOnly: true,
                          }}
                        />
                        <TextField
                          label="Monthly Installment Amount"
                          // value={financeLoan.monthlyInstalmentAmount}
                          value={parseFloat(financeLoan.monthlyInstalmentAmount).toFixed(2)}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                            readOnly: true,
                          }}
                        />
                        <TextField
                          label="Total Loan Payment"
                          value={financeLoan.totalLoanPayment}
                          fullWidth
                          margin="normal"
                          disabled={saveStatus.financeLoan.disabled}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                            readOnly: true,
                          }}
                        />

                        <Button variant="contained"
                          color={saveStatus.financeLoan.color}
                          disabled={saveStatus.financeLoan.disabled}
                          className={classes.customButton} onClick={() => handleSave('financeLoan')}>
                          Save Finance Loan
                        </Button>
                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color={saveStatus.attachmentLoan.color} disabled={saveStatus.attachmentLoan.disabled}>
                        Attachment Loan
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="formSection">
                        <UploadImage customerId={customerId} onComplete={handleUploadComplete} />

                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default NewCustomerLoan;
