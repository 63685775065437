import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button
} from '@mui/material';
import axios from 'axios';
import { DateTime } from 'luxon';
const SalaryData = () => {
  const [basicInfo, setBasicInfo] = useState({
    name: '',
    mobile: '',
    position: '',
    NIC: '',
    bankName: '',
    ac: '',
    // salaryMonth: "2024-11"
    salaryMonth: DateTime.now().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-MM')
  });
  const [incomeDetails, setIncomeDetails] = useState([
    { label: 'Salary', amount: 0, epf: true, socso: true, pcb: true, eis: true },
    { label: 'Bonus', amount: 0, epf: true, socso: true, pcb: true, eis: true },
    { label: 'Overtime', amount: 0, epf: true, socso: true, pcb: true, eis: true },
    { label: 'Transport Allowance', amount: 0, epf: true, socso: true, pcb: true, eis: true },
    { label: 'Other Allowance', amount: 0, epf: true, socso: true, pcb: true, eis: true },
    { label: 'Commission', amount: 0, epf: true, socso: true, pcb: true, eis: true },
    { label: 'Unpaid Leave', amount: 0, epf: true, socso: true, pcb: true, eis: true },
  ]);
  // const [incomeDetails, setIncomeDetails] = useState([
  //   { label: 'Salary', amount: 10000, epf: true, socso: true, pcb: true, eis: true },
  //   { label: 'Bonus', amount: 1000, epf: false, socso: false, pcb: false, eis: false },
  //   { label: 'Overtime', amount: 61.54, epf: true, socso: true, pcb: true, eis: true },
  //   { label: 'Transport Allowance', amount: 0, epf: true, socso: true, pcb: true, eis: true },
  //   { label: 'Other Allowance', amount: 0, epf: true, socso: true, pcb: true, eis: true },
  //   { label: 'Commission', amount: 0, epf: true, socso: true, pcb: true, eis: true },
  //   { label: 'Unpaid Leave', amount: -3846.2, epf: true, socso: true, pcb: true, eis: true },
  // ]);

  const [epfEmployeeRate, setEpfEmployeeRate] = useState(11);
  const [epfEmployerRate, setEpfEmployerRate] = useState(12);
  const [socsoEmployeeRate, setSocsoEmployeeRate] = useState(0.5);
  const [socsoEmployerRate, setSocsoEmployerRate] = useState(1.75);
  const [pcbRate, setPcbRate] = useState(3);
  const [eisRate, setEisRate] = useState(0.2);

  const [epfEmployee, setEpfEmployee] = useState('');
  const [epfEmployer, setEpfEmployer] = useState('');
  const [socsoEmployee, setSocsoEmployee] = useState('');
  const [socsoEmployer, setSocsoEmployer] = useState('');
  const [pcbTotal, setPcbTotal] = useState('');
  const [eisEmployee, setEisEmployee] = useState('');
  const [eisEmployer, setEisEmployer] = useState('');
  const [netSalary, setNetSalary] = useState(0);
  const [advanceDeduction, setAdvanceDeduction] = useState(0);
  const [staffLoanDeduction, setStaffLoanDeduction] = useState(0);

  const [epfAppliedOriginal, setEpfAppliedOriginal] = useState(0);
  const [epfAppliedCapped, setEpfAppliedCapped] = useState(0);
  const apiURL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    calculateDeductions();
  }, [incomeDetails, epfEmployeeRate, epfEmployerRate, socsoEmployeeRate, socsoEmployerRate, pcbRate, eisRate, advanceDeduction, // Add advanceDeduction as a dependency
    staffLoanDeduction]);
  const calculateDeductions = () => {
    const totalIncome = incomeDetails.reduce((total, item) => total + item.amount, 0);

    // Calculate EPF-applied salary excluding non-EPF items like Bonus
    let epfBaseIncome = incomeDetails
      .filter(item => item.epf && item.label !== 'Bonus')
      .reduce((total, item) => total + item.amount, 0);

    setEpfAppliedOriginal(epfBaseIncome);

    // Apply capping and rounding for EPF
    if (epfBaseIncome < 1500) {
      epfBaseIncome = 1500;
    } else if (epfBaseIncome >= 1500 && epfBaseIncome <= 5000) {
      epfBaseIncome = Math.ceil(epfBaseIncome / 20) * 20;
      setEpfEmployerRate(13);
    } else if (epfBaseIncome > 5000 && epfBaseIncome <= 10000) {
      epfBaseIncome = Math.ceil(epfBaseIncome / 100) * 100;
      setEpfEmployerRate(12);
    } else {
      epfBaseIncome = 10000;
      setEpfEmployerRate(12);
    }

    setEpfAppliedCapped(epfBaseIncome);

    const epfEmployeeTotal = epfBaseIncome * (epfEmployeeRate / 100);
    const epfEmployerTotal = epfBaseIncome * (epfEmployerRate / 100);

    // SOCSO and EIS Base Income Logic with Fixed Cap at 6000
    let socsoEisBaseIncome;
    if (epfAppliedOriginal > 6000) {
      socsoEisBaseIncome = 6000; // Cap at 6000 for SOCSO and EIS
    } else if (epfAppliedOriginal > 0) {
      // Avoid uninitialized or zero values; calculate as per logic
      const socsoEisMin = Math.floor(epfAppliedOriginal / 100) * 100;
      const socsoEisMax = socsoEisMin + 100;
      socsoEisBaseIncome = (socsoEisMin + socsoEisMax) / 2;
    } else {
      socsoEisBaseIncome = 6000; // Default fallback to 6000
    }

    const socsoEmployeeTotal = socsoEisBaseIncome * (socsoEmployeeRate / 100);
    const socsoEmployerTotalCalc = socsoEisBaseIncome * (socsoEmployerRate / 100);
    const eisEmployeeTotal = socsoEisBaseIncome * (eisRate / 100);
    const eisEmployerTotalCalc = socsoEisBaseIncome * (eisRate / 100);

    // setEpfEmployee(`${epfBaseIncome} * ${epfEmployeeRate}% = ${epfEmployeeTotal.toFixed(2)}`);
    // setEpfEmployer(`${epfBaseIncome} * ${epfEmployerRate}% = ${epfEmployerTotal.toFixed(2)}`);
    // setSocsoEmployee(`${socsoEisBaseIncome} * ${socsoEmployeeRate}% = ${socsoEmployeeTotal.toFixed(2)}`);
    // setSocsoEmployer(`${socsoEisBaseIncome} * ${socsoEmployerRate}% = ${socsoEmployerTotalCalc.toFixed(2)}`);
    // setEisEmployee(`${socsoEisBaseIncome} * ${eisRate}% = ${eisEmployeeTotal.toFixed(2)}`);
    // setEisEmployer(`${socsoEisBaseIncome} * ${eisRate}% = ${eisEmployerTotalCalc.toFixed(2)}`);
    setEpfEmployee((epfBaseIncome * (epfEmployeeRate / 100)).toFixed(2));
    setEpfEmployer((epfBaseIncome * (epfEmployerRate / 100)).toFixed(2));
    setSocsoEmployee((socsoEisBaseIncome * (socsoEmployeeRate / 100)).toFixed(2));
    setSocsoEmployer((socsoEisBaseIncome * (socsoEmployerRate / 100)).toFixed(2));
    setEisEmployee((socsoEisBaseIncome * (eisRate / 100)).toFixed(2));
    setEisEmployer((socsoEisBaseIncome * (eisRate / 100)).toFixed(2));

    const pcbTotal = totalIncome * (pcbRate / 100);
    // setPcbTotal(`${totalIncome} * ${pcbRate}% = ${pcbTotal.toFixed(2)}`);
    setPcbTotal((pcbTotal).toFixed(2));;


    // const totalDeductions = epfEmployeeTotal + socsoEmployeeTotal + pcbTotal + eisEmployeeTotal;
    // setNetSalary((totalIncome - totalDeductions).toFixed(2));
    const totalDeductions = epfEmployeeTotal + socsoEmployeeTotal + pcbTotal + eisEmployeeTotal + advanceDeduction + staffLoanDeduction;
    setNetSalary((totalIncome - totalDeductions).toFixed(2));

  };

  const totalEmployeeDeduction = (
    parseFloat(epfEmployee || 0) +
    parseFloat(socsoEmployee || 0) +
    parseFloat(pcbTotal || 0) +
    parseFloat(eisEmployee || 0) +
    parseFloat(advanceDeduction || 0) +
    parseFloat(staffLoanDeduction || 0)
  ).toFixed(2);

  const totalEmployerContribution = (
    parseFloat(epfEmployer || 0) +
    parseFloat(socsoEmployer || 0) +
    parseFloat(eisEmployer || 0)
  ).toFixed(2);


  const totalDebit = incomeDetails
    .filter(item => item.amount > 0) // Filter positive values for debit
    .reduce((total, item) => total + item.amount, 0);

  const totalCredit = incomeDetails
    .filter(item => item.amount < 0) // Filter negative values for credit
    .reduce((total, item) => total + Math.abs(item.amount), 0) +
    parseFloat(advanceDeduction) +
    parseFloat(staffLoanDeduction) +
    parseFloat(epfEmployee || 0) +
    parseFloat(socsoEmployee || 0) +
    parseFloat(eisEmployee || 0) +
    parseFloat(pcbTotal || 0);

  // const totalEmployeeDeduction = (parseFloat(epfEmployee.split('= ')[1]) + parseFloat(socsoEmployee.split('= ')[1]) + parseFloat(pcbTotal.split('= ')[1]) + parseFloat(eisEmployee.split('= ')[1])).toFixed(2);
  // const totalEmployerContribution = (parseFloat(epfEmployer.split('= ')[1]) + parseFloat(socsoEmployer.split('= ')[1]) + parseFloat(eisEmployer.split('= ')[1])).toFixed(2);


  const handleSaveSalary = async () => {
    const salaryData = {
      basicInfo,
      incomeDetails,
      epfEmployeeRate,
      epfEmployee,
      epfEmployerRate,
      epfEmployer,
      socsoEmployeeRate,
      socsoEmployee,
      socsoEmployer,
      socsoEmployerRate,
      pcbRate,
      pcbTotal,
      eisRate,
      eisEmployee,
      eisEmployer,
      advanceDeduction,
      staffLoanDeduction,
      netSalary
      
    };

    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage

      const response = await axios.post(`${apiURL}/salaries`, salaryData, {
        headers: {
          Authorization: `Bearer ${token}`, // Attach token in Authorization header
          'Content-Type': 'application/json',
        },
      });

      console.log('Salary data saved successfully:', response.data);
      alert('Salary data submitted successfully!');
    } catch (error) {
      console.error('Error saving salary data:', error.response?.data || error.message);
      alert('Failed to submit salary data. Please try again.');
    }
  };

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>Salary Details</Typography>
      <Box mb={3}>
        <Typography variant="h6">Basic Information</Typography>
        <TextField
          label="Employee Name"
          value={basicInfo.name}
          onChange={(e) => setBasicInfo({ ...basicInfo, name: e.target.value })}

          margin="normal"
          sx={{ ml: 2 }}
        />
        <TextField
          label="Mobile"
          type="tel"
          value={basicInfo.mobile}
          onChange={(e) => setBasicInfo({ ...basicInfo, mobile: e.target.value })}

          margin="normal"
          sx={{ ml: 2 }}
        />
        <TextField
          label="Position"
          value={basicInfo.position}
          onChange={(e) => setBasicInfo({ ...basicInfo, position: e.target.value })}

          margin="normal"
          sx={{ ml: 2 }}
        />
        <TextField
          label="NIC"
          value={basicInfo.NIC}
          onChange={(e) => setBasicInfo({ ...basicInfo, NIC: e.target.value })}

          margin="normal"
          sx={{ ml: 2 }}
        />
        <TextField
          label="Bank Name"
          value={basicInfo.bankName}
          onChange={(e) => setBasicInfo({ ...basicInfo, bankName: e.target.value })}

          margin="normal"
          sx={{ ml: 2 }}
        />
        <TextField
          label="Account No"
          value={basicInfo.ac}
          onChange={(e) => setBasicInfo({ ...basicInfo, ac: e.target.value })}

          margin="normal"
          sx={{ ml: 2 }}
        />
        <TextField
          label="Salary Month"
          type="month" // This enables the month picker
          value={basicInfo.salaryMonth}
          onChange={(e) => setBasicInfo({ ...basicInfo, salaryMonth: e.target.value })}
          margin="normal"
          sx={{ ml: 2 }}
        />


      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Income Type</TableCell>
              <TableCell>Amount (RM)</TableCell>
              <TableCell align="center">EPF</TableCell>
              <TableCell align="center">SOCSO</TableCell>
              <TableCell align="center">PCB</TableCell>
              <TableCell align="center">EIS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {incomeDetails.map((item, index) => (
    <TableRow key={index}>
      <TableCell>{item.label}</TableCell>
      <TableCell>
        <TextField
          type="number"
          value={item.amount}
          onChange={(e) => {
            const updatedDetails = [...incomeDetails];
            updatedDetails[index].amount = parseFloat(e.target.value) || 0;
            setIncomeDetails(updatedDetails);
          }}
          fullWidth
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={item.epf}
          onChange={(e) => {
            const updatedDetails = [...incomeDetails];
            updatedDetails[index].epf = e.target.checked;
            updatedDetails[index].socso = e.target.checked;
            updatedDetails[index].pcb = e.target.checked;
            updatedDetails[index].eis = e.target.checked;
            setIncomeDetails(updatedDetails);
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={item.socso}
          onChange={(e) => {
            const updatedDetails = [...incomeDetails];
            updatedDetails[index].epf = e.target.checked;
            updatedDetails[index].socso = e.target.checked;
            updatedDetails[index].pcb = e.target.checked;
            updatedDetails[index].eis = e.target.checked;
            setIncomeDetails(updatedDetails);
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={item.pcb}
          onChange={(e) => {
            const updatedDetails = [...incomeDetails];
            updatedDetails[index].epf = e.target.checked;
            updatedDetails[index].socso = e.target.checked;
            updatedDetails[index].pcb = e.target.checked;
            updatedDetails[index].eis = e.target.checked;
            setIncomeDetails(updatedDetails);
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={item.eis}
          onChange={(e) => {
            const updatedDetails = [...incomeDetails];
            updatedDetails[index].epf = e.target.checked;
            updatedDetails[index].socso = e.target.checked;
            updatedDetails[index].pcb = e.target.checked;
            updatedDetails[index].eis = e.target.checked;
            setIncomeDetails(updatedDetails);
          }}
        />
      </TableCell>
    </TableRow>
  ))}
  <TableRow>
    <TableCell><strong>Total Salary (Including Bonus)</strong></TableCell>
    <TableCell colSpan={5} align="center">
      {incomeDetails.reduce((total, item) => total + item.amount, 0).toFixed(2)}
    </TableCell>
  </TableRow>
  <TableRow>
    <TableCell><strong>EPF Applied Salary (Excluding Bonus)</strong></TableCell>
    <TableCell colSpan={5} align="center">
      Original: {epfAppliedOriginal.toFixed(2)} | Capped and Rounded: {epfAppliedCapped.toFixed(2)}
    </TableCell>
  </TableRow>
</TableBody>

        </Table>
      </TableContainer>

      <Box mt={3}>
        <Typography variant="h6">Deductions and Net Salary</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Deduction Type</strong></TableCell>
                <TableCell align="center"><strong>Employee %</strong></TableCell>
                <TableCell align="center"><strong>Employee Amount</strong></TableCell>
                <TableCell align="center"><strong>Employer %</strong></TableCell>
                <TableCell align="center"><strong>Employer Amount</strong></TableCell>
              </TableRow>

            </TableHead>
            <TableBody>


              <TableRow>
                <TableCell>EPF</TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={epfEmployeeRate}
                    onChange={(e) => setEpfEmployeeRate(parseFloat(e.target.value) || 0)}
                    h
                  />
                </TableCell>

                <TableCell align="center">
                  <TextField
                    type="text"
                    value={epfEmployee}
                    readOnly

                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={epfEmployerRate}
                    onChange={(e) => setEpfEmployerRate(parseFloat(e.target.value) || 0)}

                  />
                </TableCell>

                {/* Employer Amount (Calculated and Read-Only) */}
                <TableCell align="center">
                  <TextField
                    type="text"
                    value={epfEmployer}
                    readOnly

                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>SOCSO</TableCell>

                {/* Editable Employee Percentage */}
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={socsoEmployeeRate}
                    onChange={(e) => setSocsoEmployeeRate(parseFloat(e.target.value) || 0)}

                  />
                </TableCell>

                {/* Employee Amount (Calculated and Read-Only) */}
                <TableCell align="center">
                  <TextField
                    type="text"
                    value={socsoEmployee}
                    readOnly

                  />
                </TableCell>

                {/* Editable Employer Percentage */}
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={socsoEmployerRate}
                    onChange={(e) => setSocsoEmployerRate(parseFloat(e.target.value) || 0)}

                  />
                </TableCell>

                {/* Employer Amount (Calculated and Read-Only) */}
                <TableCell align="center">
                  <TextField
                    type="text"
                    value={socsoEmployer}
                    readOnly

                  />
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell>EIS</TableCell>

                {/* Editable Employee Percentage */}
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={eisRate}
                    onChange={(e) => setEisRate(parseFloat(e.target.value) || 0)}

                  />
                </TableCell>

                {/* Employee Amount (Calculated and Read-Only) */}
                <TableCell align="center">
                  <TextField
                    type="text"
                    value={eisEmployee}
                    readOnly

                  />
                </TableCell>

                {/* Employer Percentage (EIS shares the same rate for both Employee and Employer) */}
                <TableCell align="center">
                  <TextField
                    type="text"
                    value={eisRate}
                    readOnly

                  />

                </TableCell>

                {/* Employer Amount (Calculated and Read-Only) */}
                <TableCell align="center">
                  <TextField
                    type="text"
                    value={eisEmployer}
                    readOnly

                  />
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell>PCB</TableCell>

                {/* Editable Percentage Input */}
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={pcbRate}
                    onChange={(e) => {
                      const newRate = parseFloat(e.target.value) || 0; // Ensure valid number
                      setPcbRate(newRate);

                      const totalIncome = incomeDetails.reduce((total, item) => total + item.amount, 0); // Calculate total income
                      const pcbValue = totalIncome * (newRate / 100); // Calculate PCB total

                      // Update PCB Total when the percentage changes
                      setPcbTotal(`${totalIncome.toFixed(2)} * ${newRate}% = ${pcbValue.toFixed(2)}`);
                    }}

                  />
                </TableCell>

                {/* Editable PCB Total Value */}
                <TableCell align="center">
                  <TextField
                    type="text"
                    value={pcbTotal}
                    onChange={(e) => {
                      const inputValue = parseFloat(e.target.value) || 0; // Ensure valid number
                      const totalIncome = incomeDetails.reduce((total, item) => total + item.amount, 0); // Calculate total income

                      // Calculate and update the percentage based on the total value
                      const newRate = (inputValue / totalIncome) * 100;

                      // Update both PCB Total and PCB Rate
                      setPcbRate(newRate.toFixed(2));
                      setPcbTotal(`${totalIncome.toFixed(2)} * ${newRate.toFixed(2)}% = ${inputValue.toFixed(2)}`);
                    }}

                  />
                </TableCell>

                {/* Empty Cells for Alignment */}
                <TableCell colSpan={2} />
              </TableRow>

              <TableRow>
                <TableCell>Advance Deduction</TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    type="number"
                    value={advanceDeduction}
                    onChange={(e) => setAdvanceDeduction(parseFloat(e.target.value) || 0)}
                    fullWidth
                  />
                </TableCell>
                <TableCell colSpan={2} />
              </TableRow>

              <TableRow>
                <TableCell>Staff Loan Deduction</TableCell>
                <TableCell colSpan={2}>
                  <TextField
                    type="number"
                    value={staffLoanDeduction}
                    onChange={(e) => setStaffLoanDeduction(parseFloat(e.target.value) || 0)}
                    fullWidth
                  />
                </TableCell>
                <TableCell colSpan={2} />
              </TableRow>


              <TableRow>
                <TableCell colSpan={2} align="right"><strong>Total Deduction for Employee</strong></TableCell>
                <TableCell align="center"><strong>{(parseFloat(totalEmployeeDeduction) + advanceDeduction + staffLoanDeduction).toFixed(2)}</strong></TableCell>
                <TableCell align="right"><strong>Total Employer Amount</strong></TableCell>
                <TableCell align="center"><strong>{totalEmployerContribution}</strong></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} align="right"><strong>Net Salary</strong></TableCell>
                <TableCell align="center">
                  <TextField
                    type="text"
                    value={netSalary}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>No</strong></TableCell>
              <TableCell align="center"><strong>Category</strong></TableCell>
              <TableCell align="center"><strong>Debit Amount</strong></TableCell>
              <TableCell align="center"><strong>Credit Amount</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow>
              <TableCell>01</TableCell>
              <TableCell>Basic Salary</TableCell>
              <TableCell>{incomeDetails.find(item => item.label === 'Salary')?.amount.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>02</TableCell>
              <TableCell>Bonus</TableCell>
              <TableCell>{incomeDetails.find(item => item.label === 'Bonus')?.amount.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>03</TableCell>
              <TableCell>Over Time</TableCell>
              <TableCell>{incomeDetails.find(item => item.label === 'Overtime')?.amount.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>04</TableCell>
              <TableCell>Transport Allowance</TableCell>
              <TableCell>{incomeDetails.find(item => item.label === 'Transport Allowance')?.amount.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>05</TableCell>
              <TableCell>Other Allowance</TableCell>
              <TableCell>{incomeDetails.find(item => item.label === 'Other Allowance')?.amount.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>06</TableCell>
              <TableCell>Staff Commission</TableCell>
              <TableCell>{incomeDetails.find(item => item.label === 'Commission')?.amount.toFixed(2)} </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>07</TableCell>
              <TableCell>Unpaid Leave</TableCell>
              <TableCell></TableCell>
              <TableCell>{incomeDetails.find(item => item.label === 'Unpaid Leave')?.amount.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>08</TableCell>
              <TableCell>EPF</TableCell>
              <TableCell></TableCell>
              <TableCell>{epfEmployee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>09</TableCell>
              <TableCell>SOCSO</TableCell>
              <TableCell></TableCell>
              <TableCell>{socsoEmployee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>10</TableCell>
              <TableCell>EIS</TableCell>
              <TableCell></TableCell>
              <TableCell>{eisEmployee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>11</TableCell>
              <TableCell>PCB Actual</TableCell>
              <TableCell></TableCell>
              <TableCell>{pcbTotal}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>12</TableCell>
              <TableCell>Advance Deduction</TableCell>
              <TableCell></TableCell>
              <TableCell>{advanceDeduction}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>13</TableCell>
              <TableCell>Staff Loan Deduction</TableCell>
              <TableCell></TableCell>
              <TableCell>{staffLoanDeduction}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}><strong>Total</strong></TableCell>
              <TableCell align="left"><strong>{totalDebit.toFixed(2)}</strong></TableCell>
              <TableCell align="left"><strong>{totalCredit.toFixed(2)}</strong></TableCell>
            </TableRow>
            <TableRow >
              <TableCell align="right" colSpan={2}>
                <Typography variant="h4" gutterBottom> Net Salary</Typography>
              </TableCell>
              <TableCell olSpan={2}>
                <Typography variant="h4" gutterBottom>{netSalary}</Typography> </TableCell>

            </TableRow>

          </TableBody>
        </Table>
      </Box>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveSalary}
          fullWidth
          size="large"
        >
          Submit Salary Data
        </Button>
      </Box>

    </Box>
  );
};

export default SalaryData;
