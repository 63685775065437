import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl,
  InputLabel, Select, MenuItem, Snackbar, Alert, Typography, TextField, Divider, Grid, Box
} from '@mui/material';
import axios from 'axios';
import jsPDF from 'jspdf';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode as a named import
import 'jspdf-autotable';

const UpdatePaymentTerms = ({ open, handleClose, term }) => {
  const [status, setStatus] = useState(term?.status || '');
  const [cashPayment, setCashPayment] = useState('');
  const [additionalCharge, setAdditionalCharge] = useState(0);
  const [sst, setSst] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [balance, setBalance] = useState(0);
  const [customerData, setCustomerData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [pvNumber, setPvNumber] = useState(term?.paymentVoucherNumber || '');
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (term?.financeLoanId?._id) {
      fetchPaymentAndCustomerData(term.financeLoanId._id);
    }
  }, [term]);

  useEffect(() => {
    if (term?.dueDate) {
      const calculatedAdditionalCharge = calculateAdditionalCharge(term.dueDate);
      setAdditionalCharge(calculatedAdditionalCharge);
      calculateTotals(calculatedAdditionalCharge, term.monthlyInstalmentAmount);
    }
  }, [customerData, term]);

  const fetchPaymentAndCustomerData = async (loanId) => {
    try {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) throw new Error("Token not found in localStorage");

      // Decode token to get userId
      let userId;
      try {
        const decodedToken = jwtDecode(storedToken);
        userId = decodedToken?.id; // Assuming 'id' is the correct key for the user ID
        console.log("Decoded Token:", decodedToken); // Debugging: log the decoded token
        console.log("User ID extracted:", userId); // Debugging: log the user ID extracted
      } catch (decodeError) {
        console.error("Token decoding failed:", decodeError);
        return;
      }

      if (!userId) {
        console.error("User ID is undefined after decoding");
        return;
      }

      // Fetch customer data
      const response = await axios.get(`${apiURL}/paymenterms/${loanId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        }
      });
      setCustomerData(response.data);

      // Log URL and userId before making the request
      console.log(`Requesting user data with userId: ${userId}`);
      console.log(`Request URL: ${apiURL}/users/${userId}`);

      // Fetch user data based on decoded userId
      const userResponse = await axios.get(`${apiURL}/users/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setUserData(userResponse.data);
    } catch (error) {
      console.error("Error fetching payment and customer data:", error);
    }
  };

  const calculateAdditionalCharge = (dueDate) => {
    const totalInterestAmount = customerData?.totalLoanPaymentAmount - customerData?.totalFinancingAmount || 0;
    const monthlyInterestValue = totalInterestAmount / customerData?.balanceTenure || 0;
    const dailyDueCharge = monthlyInterestValue / 30;

    const today = new Date();
    const paymentDueDate = new Date(dueDate);
    const timeDifference = today.getTime() - paymentDueDate.getTime();
    const overdueDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

    if (overdueDays > 0) {
      let additionalCharge = overdueDays * dailyDueCharge;
      if (overdueDays >= 90) {
        additionalCharge += 50;
      }
      return parseFloat(additionalCharge.toFixed(2));
    }
    return 0;
  };

  const calculateAdjustmentToNearestFiveCents = (subtotal) => {
    const lastTwoDigits = Math.round((subtotal * 100) % 100);
    const remainder = lastTwoDigits % 5;
    if (remainder === 0) return 0;
    return (5 - remainder) / 100;
  };

  const calculateTotals = (additionalCharge, monthlyInstalmentAmount) => {
    const total = monthlyInstalmentAmount + additionalCharge;
    const calculatedSST = total * 0.0;
    const subtotal = total + calculatedSST;
    const dynamicAdjustment = calculateAdjustmentToNearestFiveCents(subtotal);
    const calculatedGrandTotal = subtotal + dynamicAdjustment;

    setSst(parseFloat(calculatedSST.toFixed(2)));
    setAdjustment(dynamicAdjustment);
    setGrandTotal(parseFloat(calculatedGrandTotal.toFixed(2)));
  };

  const handleAdditionalChargeChange = (e) => {
    const newAdditionalCharge = parseFloat(e.target.value) || 0;
    setAdditionalCharge(newAdditionalCharge);
    calculateTotals(newAdditionalCharge, term.monthlyInstalmentAmount);
  };

  const handleCashPaymentChange = (e) => {
    const payment = parseFloat(e.target.value) || 0;
    setCashPayment(payment);
    const calculatedBalance = grandTotal - payment;
    setBalance(calculatedBalance.toFixed(2));
  };

  const handleUpdate = async () => {
    try {
      const storedToken = localStorage.getItem('token');
      await axios.put(`${apiURL}/paymenterms/${term._id}`,
        { status: status, additionalCharge: additionalCharge },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          }
        });
      setSnackbarMessage('Update successful!');
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      console.error('Error updating payment term:', error);
      setSnackbarMessage('Error updating payment term');
      setSnackbarOpen(true);
    }
  };

  const handleDownloadReceipt = () => {
    const doc = new jsPDF();
    const today = new Date();
  
    // Set font size for consistency
    doc.setFontSize(12);
  
    // Outlet Information
    doc.setFontSize(14);
    doc.text(userData?.outletId?.name || 'Outlet Name', 105, 15, { align: 'center' });
    doc.setFontSize(12);
    doc.text(`Address: ${userData?.outletId?.location || 'Outlet Address'}`, 105, 23, { align: 'center' });
    doc.text(`Tel: ${userData?.outletId?.phone || 'Phone Number'}`, 105, 30, { align: 'center' });
    doc.text(`Email: ${userData?.outletId?.email || 'Email Address'}`, 105, 37, { align: 'center' });
  
    // Add a divider
    doc.line(10, 40, 200, 40);
  
    // Customer Information and Receipt Details
    doc.text('Customer Information', 14, 50);
    doc.text(`Name: ${customerData?.customerName || 'Customer Name'}`, 14, 58);
    doc.text(`Mobile: ${customerData?.mobileNumber || 'Mobile Number'}`, 14, 66);
    
    doc.setFontSize(12);
    doc.text('Official Receipt', 150, 50, { align: 'left' });
    doc.text(`${pvNumber || 'N/A'}`, 150, 58, { align: 'left' });
    doc.text(`Payment Type: Cash`, 150, 66, { align: 'left' });
    doc.text(`Date: ${today.toLocaleDateString()}`, 150, 74, { align: 'left' });
    
    // Add the generated Payment Receipt Code
  
    // Add a divider
    doc.line(10, 80, 200, 80);
  
    // Payment Details
    doc.text('Payment Details', 14, 88);
    doc.text(`Item: ${term?.installmentNumber || 'N/A'}`, 14, 96);
    doc.text(`Due Date: ${new Date(term?.dueDate).toLocaleDateString()}`, 14, 104);
    doc.text(`Amount: RM ${term?.monthlyInstalmentAmount.toFixed(2)}`, 14, 112);
  
    doc.text(`Additional Charge: RM ${additionalCharge.toFixed(2)}`, 150, 96, { align: 'left' });
    doc.text(`TAX (10%): RM ${sst.toFixed(2)}`, 150, 104, { align: 'left' });
    doc.text(`Subtotal: RM ${(term?.monthlyInstalmentAmount + additionalCharge + sst).toFixed(2)}`, 150, 112, { align: 'left' });
    doc.text(`Adjustment: RM ${adjustment.toFixed(2)}`, 150, 120, { align: 'left' });
    doc.text(`Grand Total: RM ${grandTotal.toFixed(2)}`, 150, 128, { align: 'left' });
  
    // Add a divider
    doc.line(10, 135, 200, 135);
  
    // Grand Total in Words
    doc.setFontSize(12);
    doc.text(
      `(${numberToWords(Math.floor(grandTotal))} Ringgit ${Math.round((grandTotal % 1) * 100)} Cents Only)`,
      105,
      145,
      { align: 'center' }
    );
  
    // Footer
    doc.setFontSize(10);
    doc.text('Thank you for your payment!', 105, 160, { align: 'center' });
  
    // Save the PDF
    doc.save(`Payment_Receipt_${customerData?.customerName || 'Customer'}.pdf`);
  };
  


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const numberToWords = (num) => {
    const ones = [
      "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
    ];
    const teens = [
      "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen",
    ];
    const tens = [
      "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety",
    ];
    const thousands = ["", "Thousand", "Million", "Billion"];

    if (num === 0) return "Zero";

    let word = "";
    let i = 0;

    while (num > 0) {
      if (num % 1000 !== 0) {
        word = helper(num % 1000) + thousands[i] + " " + word;
      }
      num = Math.floor(num / 1000);
      i++;
    }

    return word.trim();

    function helper(num) {
      if (num === 0) return "";
      else if (num < 10) return ones[num] + " ";
      else if (num < 20) return teens[num - 10] + " ";
      else if (num < 100) return tens[Math.floor(num / 10)] + " " + helper(num % 10);
      else return ones[Math.floor(num / 100)] + " Hundred " + helper(num % 100);
    }
  };

  const handleGeneratePV = async () => {
    try {
      const response = await axios.get(
        `${apiURL}/paymenterms/generate-pv/${term.financeLoanId._id}/${term._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setPvNumber(response.data.pvNumber); // Set the PV number
      setSnackbarMessage('Payment Voucher Number generated successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error generating PV number:', error);
      setSnackbarMessage('Error generating Payment Voucher Number');
      setSnackbarOpen(true);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {/* <DialogTitle>Update Payment Term</DialogTitle> */}
      <DialogContent style={{ width: 550 }}>
        {customerData ? (
          <div>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={2} // Adds some margin below the outlet details
            >
              <Typography variant="h6" align="center">{userData?.outletId.name}</Typography>
              <Typography align="center">Address: {userData?.outletId.location}</Typography>
              <Typography align="center">Tel: {userData?.outletId.phone}</Typography>
              <Typography align="center">Email: {userData?.outletId.email}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography>Name: {customerData.customerName}</Typography>
                <Typography>Mobile: {customerData.mobileNumber}</Typography>
              </Box>
              <Box>
                <Typography variant="h6">Official Receipt</Typography>
                <Typography> {pvNumber || 'Generate CS'}</Typography>
                <Typography>Payment Type: Cash</Typography>
                <Typography>Settlement Date: {new Date().toLocaleDateString()}</Typography>
             {!pvNumber && (
                 <Button
                 variant="contained"
                 color="secondary"
                 onClick={handleGeneratePV}
               >
                 Generate CS
               </Button>
             )}
              </Box>
            </Box>

            {/* <Typography variant="h6" gutterBottom>Payment Receipt</Typography> */}
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography>Iterm: {term.installmentNumber} </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Due Date: {new Date(term.dueDate).toLocaleDateString()}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Amount: RM {term.monthlyInstalmentAmount.toFixed(2)}</Typography>
              </Grid>


              <Grid item xs={6}>
                <Typography>Additional Charge:</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label=" Charge (RM)"
                  fullWidth
                  value={additionalCharge}
                  onChange={handleAdditionalChargeChange} // Attach the handler here
                  inputProps={{ style: { textAlign: 'right' } }}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />
            <Typography align="right">Total: RM {parseFloat(term.monthlyInstalmentAmount + additionalCharge).toFixed(2)}</Typography>
            <Typography align="right">TAX (0%): RM {sst.toFixed(2)}</Typography>
            <Typography align="right">Subtotal: RM {(term.monthlyInstalmentAmount + additionalCharge + sst).toFixed(2)}</Typography>
            <Typography align="right">Adjustment: RM {adjustment.toFixed(2)}</Typography>
            <Typography variant="h5" align="right">Grand Total: RM {grandTotal.toFixed(2)}</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography align="right" sx={{ mt: 1, color: "gray" }}>
              ({numberToWords(Math.floor(grandTotal))} Ringgit {Math.round((grandTotal % 1) * 100)} Cents Only)
            </Typography>
            <TextField
              label="Cash Payment"
              fullWidth
              margin="normal"
              value={cashPayment}
              onChange={handleCashPaymentChange}
            />
            <Typography>Balance: RM {balance}</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : (
          <Typography>Loading customer data...</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDownloadReceipt}>Download Receipt</Button>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpdate} color="primary">Submit</Button>
      </DialogActions>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('Error') ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default UpdatePaymentTerms;
